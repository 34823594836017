import dmpCalendar from './modules/calendar';
import dmpGalleries from './modules/galleries';
import dmpMap from './modules/maps';
import dmpNavigation from './modules/navigation';
import dmpRedirect from './modules/redirect-modal';
import dmpSearch from './modules/search';

document.addEventListener('DOMContentLoaded', () => {
  // TODO find a way to test this
  const prefersReducedMotion =
    window.matchMedia('(prefers-reduced-motion: reduce)') === true ||
    window.matchMedia('(prefers-reduced-motion: reduce)').matches === true;

  // Calendar
  const calendar = document.querySelector('#js-calendar');
  if (calendar !== null) {
    dmpCalendar.init(calendar);
  }

  // Galleries
  const galleries = document.querySelectorAll('.gallery-slider');
  if (galleries.length > 0) {
    dmpGalleries.init(galleries);
    dmpGalleries.initializeLightbox();
  }

  // Map
  const detailMap = document.querySelector('#js-detail-map');
  if (detailMap !== null) {
    const title = detailMap.dataset.mapTitle;
    let coordinates = detailMap.dataset.coordinates;
    if (coordinates) {
      coordinates = coordinates.split(',').map((el) => Number(el.trim()));
    }
    dmpMap.initMap(
      detailMap,
      prefersReducedMotion,
      coordinates,
      16,
      true,
      title,
    );
  }

  // Interactive Map
  const interactiveMap = document.querySelector('#js-interactive-map');
  if (interactiveMap !== null) {
    // const title = interactiveMap.dataset.mapTitle;
    const pageId = interactiveMap.dataset.pageId;
    dmpMap.initInteractiveMap(interactiveMap, pageId, prefersReducedMotion);
  }

  // Search
  const searchSuggestions = document.querySelectorAll('.js-search-suggestions');
  if (searchSuggestions.length) {
    document.body.addEventListener('keydown', dmpSearch.navigateSearchResults);
    document.body.addEventListener('input', dmpSearch.getSearchSuggestions);
  }
});

document.addEventListener(
  'click',
  (event) => {
    if (event.target.closest('.js-search-suggestions')) {
      dmpSearch.showSearchResults(
        event.target.closest('.js-search-suggestions'),
      );
    } else {
      dmpSearch.hideSearchResults(
        document.querySelector('.js-search-suggestions.show-results'),
      );
    }
    if (event.target.closest('#js-site-search-toggle')) {
      event.preventDefault();
      dmpSearch.toggleSearch();
    } else if (event.target.closest('#site-navigation-toggle')) {
      dmpNavigation.toggleNavigation();
    } else if (event.target.matches('#site-navigation')) {
      dmpNavigation.closeNavigation();
    } else if (event.target.closest('#js-clear-interactive-map-filters')) {
      dmpMap.clearFilters();
    } else if (event.target.closest('.js-map-filter-button')) {
      event.preventDefault();
      dmpMap.updateMapMarkers(event.target.closest('.js-map-filter-button'));
    } else if (event.target.closest('.js-map-entry-button')) {
      // ! Quick'n dirty solution to enable the click on "details" link
      if (event.target.tagName === 'A') return;
      event.preventDefault();
      dmpMap.focusMarker(event.target.closest('.js-map-entry-button'));
    } else if (event.target.closest('.splide.lightbox .splide__slide a')) {
      event.preventDefault();
      const slide = event.target.closest('.splide.lightbox .splide__slide a');
      const slideId = slide.getAttribute('data-lightbox-id');
      if (slideId !== null) {
        dmpGalleries.navigateLightbox(slideId);
      }
      dmpGalleries.openLightbox();
    } else if (
      event.target.closest('#dmp-lightbox') &&
      !(
        event.target.closest('.splide__slide > img') ||
        event.target.closest('.splide__arrow')
      )
    ) {
      event.preventDefault();
      dmpGalleries.closeLightbox();
    } else if (
      event.target.matches('#js-redirect') ||
      event.target.closest('#js-redirect-close')
    ) {
      dmpRedirect.hideModal();
      dmpRedirect.resetModal();
    } else if (event.target.closest('#js-redirect-link')) {
      dmpRedirect.hideModal();
      setTimeout(dmpRedirect.resetModal, 2000);
    } else if (event.target.closest('a')) {
      // Check if url is external url
      const link = event.target.closest('a');
      if (link == null) {
        return;
      }
      const url = link.getAttribute('href');
      /* eslint-disable no-restricted-globals */
      if (url && dmpRedirect.isExternalUrl(location, url)) {
        // Display modal for external links
        event.preventDefault();
        dmpRedirect.showModal(url);
      }
    }
  },
  false,
);

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') {
    if (
      dmpGalleries.el.lightboxSlider !== null &&
      dmpGalleries.el.lightbox !== null
    ) {
      if (dmpGalleries.el.lightbox.classList.contains('dmp-lightbox-visible')) {
        dmpGalleries.el.lightboxSlider.emit('dmpCloseLightbox');
      }
    }
  }
});
